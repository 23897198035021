import * as Sentry from '@sentry/react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './assets/scss/index.scss'
import { store } from './store'
import { App } from './App'
import reportWebVitals from './reportWebVitals'
import { initializeTheme } from './themes'
import {
  AuthHandler,
  AuthProvider,
  DefaultApolloProvider,
  FirebaseAppProvider,
  InstanceSettingProvider,
} from './infrastructure'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { InitialiseSentry } from './infrastructure/SentryProvider'
import { HttpClientProvider } from './infrastructure/HttpClientProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement, {
  // Callback called when React automatically recovers from errors.
  onRecoverableError: Sentry.reactErrorHandler(),
})

InitialiseSentry()

initializeTheme()

root.render(
  <FirebaseAppProvider>
    <InstanceSettingProvider>
      <AuthProvider>
        <AuthHandler>
          <HttpClientProvider>
            <Provider store={store}>
              <DefaultApolloProvider>
                <BrowserRouter>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <App />
                  </LocalizationProvider>
                </BrowserRouter>
              </DefaultApolloProvider>
            </Provider>
          </HttpClientProvider>
        </AuthHandler>
      </AuthProvider>
    </InstanceSettingProvider>
  </FirebaseAppProvider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
