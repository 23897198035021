export const inactiveSymbol = '-'

export const tileColorOpacity = '88'

export const locale = 'en-NZ'

export const nowToleranceMs = 5000

export const msInSec = 1000
export const msInMin = 60000
export const msInDay = 86400000
export const msIn2Weeks = 1209600000

export const minuteInMs = 60000
export const dataGapSec = 150
