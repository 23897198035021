import React, { FC, ReactElement, Suspense, useCallback, useEffect, useState } from 'react'
import { DeviceViewModel, Metric } from '../../types'
import { loadExpandedMap, saveExpandedMap } from '../../services'
import { MetricTile } from '.'
import { useMetricSettings } from '../../hooks'
import { NoDataTile } from './NoDataTile'
import { Info } from '../common'
import { useDeviceStatus } from '../../hooks/useDevice'

export const DevicePanel: FC<{
  patientId: string
  viewModel: DeviceViewModel
  showControl: boolean
  children: ({
    metric,
    showControl,
    onRemove,
  }: {
    metric: Metric
    showControl: boolean
    onRemove: (id: string) => void
  }) => ReactElement
  colorSelector: (id: string) => string
}> = ({ patientId, viewModel, children, colorSelector, showControl }) => {
  const { id: deviceId, metrics } = viewModel
  const [state, setState] = useState<{ expandedMap: Record<string, 1> | undefined }>({ expandedMap: undefined })
  const metricSettings = useMetricSettings()
  const localStorageKey = `${patientId}-${deviceId}`

  useEffect(() => {
    const expandedMap = loadExpandedMap(localStorageKey)
    setState({ expandedMap })
  }, [])

  const addChart = useCallback(
    (metricName: string) => {
      const expandedMap = state.expandedMap
      if (!expandedMap) return
      expandedMap[metricName] = 1
      saveExpandedMap(localStorageKey, expandedMap)
      setState({ expandedMap: expandedMap })
    },
    [state],
  )

  const removeChart = useCallback(
    (metricName: string) => {
      const expandedMap = state.expandedMap
      if (!expandedMap) return
      delete expandedMap[metricName]
      saveExpandedMap(localStorageKey, expandedMap)
      setState({ expandedMap })
    },
    [state],
  )

  const collapsed: Metric[] = []
  const expanded: Metric[] = []
  if (state.expandedMap) {
    for (const metric of metrics) {
      if (state.expandedMap[metric.name]) {
        expanded.push(metric)
      } else {
        collapsed.push(metric)
      }
    }
  }
  collapsed.sort()
  expanded.sort()

  return (
    <div className="v-devicePanel">
      <div className="v-deviceInfo">
        <div className="v-deviceInfo-title">
          {viewModel.device?.name}
          &nbsp; &nbsp;
          {!viewModel.connectable || !viewModel.isActive ? null : <ConnectionView deviceId={viewModel.device?.id} />}
        </div>
        <div className="v-deviceInfo-subtitle">{viewModel.device?.mac}</div>
      </div>
      {collapsed.length ? (
        <div className="v-metricTileContainer">
          {collapsed.map((metric) =>
            metricSettings[metric.name]?.visible === false ? null : (
              <MetricTile metric={metric} key={metric.name} colorSelector={colorSelector} onAdd={addChart} />
            ),
          )}
        </div>
      ) : null}
      <Suspense fallback={<Info text="Loading ..." />}>
        {expanded.map((metric) =>
          metricSettings[metric.name]?.visible === false
            ? null
            : children({
                metric,
                showControl,
                onRemove: removeChart,
              }),
        )}
      </Suspense>
      {viewModel.metrics.length === 0 ? <NoDataTile /> : undefined}
    </div>
  )
}

const ConnectionView: FC<{ deviceId?: string }> = ({ deviceId }) => {
  const { loading, error, data } = useDeviceStatus(deviceId)
  if (loading || error) {
    return <></>
  }

  return data?.isConnected ? (
    <span className="v-deviceIndicator v-deviceIndicator--connected">Connected</span>
  ) : (
    <span className="v-deviceIndicator v-deviceIndicator--disconnected">Disconnected</span>
  )
}
