import React, { FC } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useModulesSettings } from '../hooks'
import { makeModuleLinkUrl } from '../utils'
import { NetworkStatusBar } from './common'

export const Sidebar: FC<{ moduleList: string[] }> = ({ moduleList }) => {
  const params = useParams()
  const moduleSettings = useModulesSettings()

  return (
    <div className="v-sidebar">
      <div className="v-sidebar-content">
        <div>
          <div className="v-sidebar-header">
            <Link to={'/'}>
              <div className="v-sidebar-logo">
                <img
                  width="45px"
                  height="30px"
                  src="/FP.png"
                  srcSet="/FP-2x.png 2x"
                  style={{ cursor: 'pointer' }}
                  alt=""
                ></img>
                <img
                  className="v-sidebar-label"
                  style={{ padding: '5px 0px 0px 2px', cursor: 'pointer' }}
                  width="100px"
                  height="19px"
                  src="/Research.svg"
                  alt=""
                ></img>
              </div>
            </Link>
          </div>
          {moduleList.map((moduleName) => {
            const { pageName, iconInMenu, pageUrl, urlParams } = moduleSettings[moduleName]
            const url = makeModuleLinkUrl(params, pageUrl, urlParams)
            return !iconInMenu ? null : (
              <Link key={moduleName} to={url}>
                <div className="v-sidebar-item">
                  <span className="v-sidebar-icon">{iconInMenu}</span>
                  <span className="v-sidebar-label">{pageName}</span>
                </div>
              </Link>
            )
          })}
        </div>
        {/* <div className="fillHeight" /> */}
        <div className="v-sidebar-footer">
          <Link to="/ward/0/settings">
            <div className="v-sidebar-item">
              <span className="v-sidebar-icon">settings</span>
              <span className="v-sidebar-label">Settings</span>
            </div>
          </Link>
          <div className="v-sidebar-info">
            <NetworkStatusBar />
          </div>
        </div>
      </div>
    </div>
  )
}
