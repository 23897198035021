import { FC } from 'react'
import { useSelector } from 'react-redux'
import { AppState, NetworkStatus } from '../../types'

export const NetworkStatusBar: FC = () => {
  const networkStatus = useSelector<AppState, NetworkStatus>((state) => state.ui.networkStatus)

  let connectionStyle = ''

  switch (networkStatus) {
    case NetworkStatus.Connecting:
      connectionStyle = 'v-networkIndicator--connecting'
      break
    case NetworkStatus.Connected:
      connectionStyle = 'v-networkIndicator--connected'
      break
    case NetworkStatus.Disconnected:
      connectionStyle = 'v-networkIndicator--disconnected'
      break
  }

  return (
    <a title={NetworkStatus[networkStatus]} className="v-networkStatusBar u-noSelect">
      <span className={`v-networkIndicator ${connectionStyle}`} />
      <span className="v-networkStatusBar-label">Network status</span>
    </a>
  )
}
