import { FC, useCallback, useEffect, useState } from 'react'
import { customEvents } from '../../customEvents'
import { switchTheme, activeThemeName } from '../../themes'
import {
  ChartZoomEvent,
  Patient,
  PatientModuleSettings,
  RangeButton,
  ToolbarButtonEvent,
  VisibleRangeChangedEvent,
} from '../../types'
import { getRangeSelectorConfig } from '../chart/configs'
import { CustomRangeModal, DatePickerModal } from '../common'
import { subDays } from 'date-fns'
import { logEvent } from 'firebase/analytics'
import { useAnalytics } from '../../hooks/useAnalytics'
import { useFirstSessionDate, useWasmSetting, activeChartKey, useModulesSettings } from '../../hooks'
import { secToDisplayMode, toRangeIndexLookup } from '../../utils'

const selectedOffset = 1

export const Toolbar: FC<{ patient: Patient }> = ({ patient }) => {
  const rangeSelectorConfig = getRangeSelectorConfig()
  const timeRangeButtons = rangeSelectorConfig.buttons!
  const lookupRangeIndex = toRangeIndexLookup(rangeSelectorConfig.buttons as RangeButton[])
  const firstSession = useFirstSessionDate(patient.id)
  const [activeId, setActive] = useState<number>(
    rangeSelectorConfig.selected ? rangeSelectorConfig.selected + selectedOffset : -1,
  )
  const { enableChartToggle } = useModulesSettings()['patient'] as PatientModuleSettings

  const analytics = useAnalytics()

  useEffect(() => {
    const unsubscribe = customEvents.subscribe(customEvents.onChartSelect, (event: Event) => {
      setActive(-1)
    })
    const unsubscribeZoom = customEvents.subscribe(customEvents.onZoom, (event: Event) => {
      const range: ChartZoomEvent = (event as CustomEvent).detail
      const index = lookupRangeIndex(range.max - range.min)
      setActive(index)
    })

    const unsubscribeDrag = customEvents.subscribe(customEvents.onNavigatorDrag, (event: Event) => {
      const { min, max } = (event as CustomEvent).detail
      const index = lookupRangeIndex(max - min)
      setActive(index)
    })
    return () => {
      unsubscribe()
      unsubscribeZoom()
      unsubscribeDrag()
    }
  }, [])

  const handleClick = useCallback((index: number, button: RangeButton) => {
    setActive(index)
    setTimeout(() => {
      customEvents.publish<ToolbarButtonEvent>(customEvents.onToolbarButtonClick, {
        id: index - selectedOffset,
        button,
      })
      logEvent(analytics, 'select_content', {
        content_type: 'rangeSelectorButton',
        content_id: button.text,
      })
    })
  }, [])

  return (
    <div className="v-toolbarWrapper u-noSelect">
      <div className="v-toolbar">
        <div
          className="v-toolbar-button v-toolbar-button--icon"
          onClick={() => {
            switchTheme()
            window.location.reload()
          }}
        >
          <span className="fontIcon">{activeThemeName === 'dark' ? 'sun' : 'moon'}</span>
        </div>
        {firstSession && firstSession <= subDays(new Date(), 14) ? (
          <DatePickerModal patient={patient} firstSession={firstSession} />
        ) : null}
        {enableChartToggle ? (
          <div className="v-toolbar-button v-toolbar-button--icon">
            <div
              className="fontIcon u-noSelect"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                const data = localStorage.getItem(activeChartKey)
                if (!data || data === 'js') {
                  localStorage.setItem(activeChartKey, 'wasm')
                } else {
                  localStorage.setItem(activeChartKey, 'js')
                }
                window.location.reload()
              }}
            >
              update
            </div>
          </div>
        ) : undefined}
        {/* <div
        className="v-toolbar-button"
        onClick={() => {
          dispatch(uiSlice.actions.toggleControl(null))
        }}
      >
        x
      </div>
      */}
        <br />
        <CustomRangeModal
          onConfirm={(rangeSeconds) => {
            if (rangeSeconds < 1) return
            const displayMode = secToDisplayMode(rangeSeconds) as RangeButton
            handleClick(0, { ...displayMode, kind: 'custom' })
          }}
        >
          <div className={`v-toolbar-button v-toolbar-button--icon ${0 === activeId ? 'is-active' : ''}`}>
            <span className="fontIcon">config</span>
          </div>
        </CustomRangeModal>

        {timeRangeButtons.map((button, index) => {
          const id = index + selectedOffset
          return (
            <div
              onClick={() => {
                handleClick(id, button as RangeButton)
              }}
              className={`v-toolbar-button ${id === activeId ? 'is-active' : ''}`}
              key={id}
            >
              {button.text}
            </div>
          )
        })}
      </div>
    </div>
  )
}
