import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  InMemoryCacheConfig,
  ApolloLink,
  Operation,
  NextLink,
  Observable,
  FetchResult,
} from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { onError } from '@apollo/client/link/error'
import { FC, ReactElement } from 'react'
import { createClient } from 'graphql-ws'
import jwt_decode from 'jwt-decode'
import { useAuthToken } from '.'
import { SentryLink } from 'apollo-link-sentry'
import * as Sentry from '@sentry/react'
import * as SentryCore from '@sentry/core'
import { extractDefinition } from 'apollo-link-sentry/lib-cjs/operation'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { useDispatch } from 'react-redux'
import { uiSlice } from '../store'
import { NetworkStatus } from '../types'

if (process.env.NODE_ENV !== 'production') {
  // Adds messages only in a dev environment
  loadDevMessages()
  loadErrorMessages()
}

const attachTokenToWebSocket = (token: string) => {
  return class AttachTokenWebSocket extends WebSocket {
    constructor(url: string | URL, protocols?: string | string[]) {
      super(url, protocols ? [token].concat(protocols) : [token])
    }
  }
}

export const cachePolicy: InMemoryCacheConfig = {
  typePolicies: {
    ActiveDevice: {
      keyFields: false,
    },
    NoteChangeEvent: {
      keyFields: false,
    },
    Device: {
      keyFields: ['id'],
    },
    Subscription: {
      fields: {
        activePatientGroupEvents: {
          merge: false,
        },
      },
    },
    Query: {
      fields: {
        patient: {
          read: (_, { args, toReference }) => {
            return toReference({
              __typename: 'Patient',
              id: args!.id,
            })
          },
        },
      },
    },
  },
}

const reloadIfExpired = (tokenExpiry: number) => {
  if (new Date().getTime() > tokenExpiry) {
    setTimeout(() => {
      window.location.reload()
    })
  }
}

export const DefaultApolloProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const token = useAuthToken()
  const tokenExpiry = jwt_decode<{ exp: number }>(token).exp * 1000
  const dispatch = useDispatch()

  const wsClient = createClient({
    url: `${process.env.NODE_ENV === 'development' ? 'ws' : 'wss'}://${window.location.host}/api/graphql`,
    retryAttempts: Infinity,
    connectionAckWaitTimeout: 30000,
    webSocketImpl: attachTokenToWebSocket(token),
    shouldRetry: () => {
      reloadIfExpired(tokenExpiry)
      return true
    },
  })
  wsClient.on('connecting', () => {
    console.debug('WS Connecting...')
    dispatch(uiSlice.actions.setNetworkStatus(NetworkStatus.Connecting))
  })
  wsClient.on('connected', (event) => {
    console.debug('WS Connected:', event)
    dispatch(uiSlice.actions.setNetworkStatus(NetworkStatus.Connected))
  })
  wsClient.on('closed', (event) => {
    console.debug('WS Closed:', event)
    dispatch(uiSlice.actions.setNetworkStatus(NetworkStatus.Disconnected))
  })
  wsClient.on('error', (event) => {
    console.debug('WS Error:', event)
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      )
    }
    if (networkError) {
      console.warn(`[Network error]: ${networkError}`, networkError)
    }

    reloadIfExpired(tokenExpiry)
  })

  const client = new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      new SentryTraceLink(),
      new SentryLink({
        setTransaction: false,
        attachBreadcrumbs: {
          includeQuery: true,
          includeVariables: true,
          includeError: true,
        },
      }),
      new GraphQLWsLink(wsClient),
    ]),
    connectToDevTools: true,
    cache: new InMemoryCache(cachePolicy),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

class SentryTraceLink extends ApolloLink {
  request(operation: Operation, forward: NextLink): Observable<FetchResult> | null {
    let manualSpan: Sentry.Span | null = null

    const name = extractDefinition(operation).name
    Sentry.startSpanManual({ name: name?.value ?? '', op: 'http.graphql' }, (span) => {
      manualSpan = span

      if (span) {
        operation.extensions['sentry-trace'] = SentryCore.spanToTraceHeader(span)
        operation.extensions['baggage'] = SentryCore.getDynamicSamplingContextFromSpan(span)
      }
    })

    return new Observable((observer) => {
      const observable = forward(operation)
      const subscription = observable.subscribe({
        next(value) {
          observer.next(value)
        },
        error(networkError) {
          observer.error(networkError)
        },
        complete() {
          manualSpan?.end()
          observer.complete()
        },
      })

      return () => subscription.unsubscribe()
    })
  }
}
