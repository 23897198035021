import { combineReducers, configureStore, createSlice } from '@reduxjs/toolkit'
import { NetworkStatus, UIState, WardMap } from './types'
import * as Sentry from '@sentry/react'

const initialState = {
  wards: {} as WardMap,
}

const rootSlice = createSlice({
  name: 'root',
  initialState: initialState,
  reducers: {},
})

const initialUIState: UIState = {
  showControl: true,
  noteModal: {},
  displayMode: { count: 4, type: 'hour', text: '4h' },
  activeWindow: { min: NaN, max: NaN, tickPositions: undefined },
  dataRange: { value: { start: undefined, end: undefined } },
  patientFilter: '',
  networkStatus: NetworkStatus.Connecting
}

export const uiSlice = createSlice({
  name: 'ui',
  initialState: initialUIState,
  reducers: {
    toggleControl: (state, action) => {
      state.showControl = !state.showControl
      return state
    },
    setDisplayMode: (state, action) => {
      state.displayMode = action.payload
    },
    setActiveWindow: (state, action) => {
      state.activeWindow = action.payload
    },
    setDataRange: (state, action) => {
      state.dataRange = action.payload
    },
    showNoteModal: (state, action) => {
      state.noteModal = action.payload
      return state
    },
    setFilter: (state, action) => {
      state.patientFilter = action.payload
      return state
    },
    setNetworkStatus: (state, action) => {
      state.networkStatus = action.payload
      return state
    }
  },
})

const reducer = combineReducers({
  root: rootSlice.reducer,
  ui: uiSlice.reducer,
})

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === 'ui/setActiveWindow') {
      // Return null to not log the action, as this can occur every second in live view
      return null
    }

    return action
  },
})

export const store = configureStore({
  reducer: reducer,
  enhancers: (defaultEnhancers) => {
    return defaultEnhancers.concat(sentryReduxEnhancer)
  },
})
