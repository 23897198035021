import { gql, useApolloClient } from '@apollo/client'
import { useEffect } from 'react'

const SUBSCRIBE_DEVICE_UPDATE_EVENT = gql`
  subscription DeviceUpdate($patientId: String!) {
    deviceUpdatedEvent(patientId: $patientId) {
      id
      modelId
      status {
        id
        isConnected
      }
    }
  }
`

export const useDeviceUpdatedSubscription = (patientId?: string) => {
  const client = useApolloClient()
  useEffect(() => {
    if (!patientId) return
    const subscription = client
      .subscribe({
        query: SUBSCRIBE_DEVICE_UPDATE_EVENT,
        variables: { patientId },
      }).subscribe(() => {
        // call subscribe here will trigger cache update, no need to handle response
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [])
}
